<template>
    <div class="p-15">
    <div class="p-10 s_flex_bian_c s_bf m-b-15 tw-rounded-sm" @click="link('second')">
      <img class="width-55 height-55 tw-rounded-sm" src="@/assets/image/second_icon.png" alt="">
      <div class="width80">
        <p class="fz-16">二级包装关联</p>
        <p class="tw-text-gray-400 m-t-5">后关联物流码,大标，小标二级关联</p>
      </div>
    </div>
    <div class="p-10 s_flex_bian_c s_bf m-b-15 tw-rounded-sm" @click="link('three')">
      <img class="width-55 height-55 tw-rounded-sm" src="@/assets/image/three_icon.png" alt="">
      <div class="width80">
        <p class="fz-16">三级包装关联</p>
        <p class="tw-text-gray-400 m-t-5">后关联物流码,大标，中标，小标三级关联</p>
      </div>
    </div>
        <div class="p-10 s_flex_bian_c s_bf tw-rounded-sm" @click="link('dismiss')">
      <img class="width-55 height-55 tw-rounded-sm" src="@/assets/image/dismiss_icon.png" alt="">
      <div class="width80">
        <p class="fz-16">解除包装关联</p>
        <p class="tw-text-gray-400 m-t-5">解除后关联的关联关系</p>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
export default {
    setup(){
        const router=useRouter()
        const link=(path)=>{
            router.push({path:`/relation/${path}`})
        }
        return{
            link
        }
    }
}
</script>